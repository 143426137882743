// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './components/Navigation/Navigation';
import HomePage from './pages/Home/Home';
import AboutPage from './pages/About/About';
import EducationPage from './pages/Education/Education';
import EngineeringPage from './pages/Engineering/Engineering';
import TravelPage from './pages/Travel/Travel';
import SkiingPage from './pages/Skiing/Skiing';
import ContactPage from './pages/Contact/Contact'; 
import Footer from './components/Footer/Footer';
import LifePage from './pages/Life/Life';
import LenexaPage from './pages/Engineering/Lenexa/Lenexa';

import TasmaniaTravelPage from './pages/Travel/Tasmania';
import PerthTravelPage from './pages/Travel/Perth';
import PhillipIslandTravelPage from './pages/Travel/PhillipIsland';
import MoonlitPage from './pages/Travel/Moonlit';
import GreatOceanRaodTravelPage from './pages/Travel/GreatOceanRoad';
import BrisbaneTravelPage from './pages/Travel/Brisbane';
import SydneyTravelPage from './pages/Travel/Sydney';
import YarraValleyTravelPage from './pages/Travel/YarraValley';
import MelbourneZooTravelPage from './pages/Travel/MelbourneZoo';
import QueenstwonTravlePage from './pages/Travel/Queenstown';

import Go1ProjectPage from './pages/Engineering/Go1/Go1';

import './styles/global.css'; // Ensure global.css is imported here


function App() {
  return (
    <Router>
      <div className="app-container">
        {/* Navigation */}
        <Navigation />
        
        {/* Main Content */}
        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/education" element={<EducationPage />} />
            <Route path="/engineering" element={<EngineeringPage />} />
            <Route path="/travel" element={<TravelPage />} />
            <Route path="/skiing" element={<SkiingPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/life" element={<LifePage />} />
            <Route path="/engineering/lenexa" element={<LenexaPage />} />

            <Route path="/travel/tasmania" element={<TasmaniaTravelPage />} />
            <Route path="/travel/perth" element={<PerthTravelPage />} />
            <Route path="/travel/phillip-island" element={<PhillipIslandTravelPage />} />
            <Route path="/travel/moonlit" element={<MoonlitPage />} />
            <Route path="/travel/great-ocean-road" element={<GreatOceanRaodTravelPage />} />
            <Route path="/travel/sydney" element={<SydneyTravelPage />} />
            <Route path="/travel/brisbane" element={<BrisbaneTravelPage />} />
            <Route path="/travel/yarra-valley" element={<YarraValleyTravelPage />} />
            <Route path="/travel/melbourne-zoo" element={<MelbourneZooTravelPage />} />
            <Route path="/travel/queenstown" element={<QueenstwonTravlePage />} />

            <Route path="/engineering/go1" element={<Go1ProjectPage/>} />

          </Routes>
        </main>
        
        {/* Footer */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
