import React from 'react';
import EngineeringProjectPage from '../../../components/EngineeringProjectPage/EngineeringProjectPage'; // Adjust path as needed

import Go1Img from '../../../assets/images/Engineering/go1.jpg';
import MazeAndGo1Img from '../../../assets/images/Engineering/Go1/MazeAndGo1.jpg';
import VSLAMImg from '../../../assets/images/Engineering/Go1/VSLAM.png';
import RRTSIMImg from '../../../assets/images/Engineering/Go1/RRTSIM.jpg';

// import Img from '../../../assets/images/Engineering/Go1';

const MyEngineeringProject = () => {
  return (
    <EngineeringProjectPage
      title="Navigation Control for Quadrupped Robot (Unitree Go1)"
      subtitle="From feasibility research to real word autonomous navigation demo"
      featuredImage={{
        src: Go1Img,
        alt: 'Completed modular bridge over a valley',
        caption: 'A newly assembled modular bridge section spanning the valley.'
      }}
      projectIntro="Project Details"
      
      galleryImages={[
        { src: MazeAndGo1Img, alt: 'Steel bridge components' },
        { src: VSLAMImg, alt: 'Engineers assembling modules' },
        { src: RRTSIMImg, alt: 'Module installation over pillars' },
      ]}
      
      projectPlan={[
        {
          phaseNumber: 1,
          title: 'Literature Review and Feasibility Study',
          activities: [
            { icon: 'drafting-compass', text: 'Initial CAD designs and structural simulations' },
            { icon: 'laptop-code', text: 'Finite element analysis to ensure structural integrity' }
          ]
        },
        {
          phaseNumber: 2,
          title: 'Roadmap Design and Work Distribution',
          activities: [
            { icon: 'industry', text: 'Prefabrication of steel modules off-site in a controlled environment' },
            { icon: 'hard-hat', text: 'Quality checks and dimensional verifications' }
          ]
        },
        {
          phaseNumber: 3,
          title: 'Function Unit Development',
          activities: [
            { icon: 'truck', text: 'Transportation of modules to the site' },
            { icon: 'tools', text: 'On-site bolt-together assembly with minimal heavy machinery' }
          ]
        },
        {
          phaseNumber: 4,
          title: 'Integration',
          activities: [
            { icon: 'clipboard-check', text: 'Load testing and deflection measurements' },
            { icon: 'check-circle', text: 'Final inspections and handover to the client' }
          ]
        },
        {
          phaseNumber: 5,
          title: 'Iterative Development',
          activities: [
            { icon: 'clipboard-check', text: 'Load testing and deflection measurements' },
            { icon: 'check-circle', text: 'Final inspections and handover to the client' }
          ]
        }
      ]}
      
      challenges="Limited Documentaion for unitree go1 robot control, hardware constraint on the communication interface, Hardware limit on computing power"
      solutions="By modularising the bridge components, we minimised the need for large machinery. Components were bolted together quickly, reducing overall environmental impact and project timelines."
      
      materials={[
        {
          title: 'Unitree Go1 Quadruped Robot Education Version',
          description: 'Go1 is a commercialised quadruped robot costing 10 times less than Boston Dynamics spot, providing accessable platform for quadruped robot control research and algorithm implmentaion.',
          bulletPoints: [
            'High-strength, low-alloy steel',
            'Corrosion-resistant coatings',
            'Prefabricated connection points for easy assembly'
          ],
          images: [
            { src: '/images/steel-module.jpg', alt: 'Steel module ready for transport' },
          ]
        },
        {
          title: 'Intel RealSense D455 Depth Camera',
          description: 'Lightweight composite panels for the bridge deck surface.',
          bulletPoints: [
            'Anti-slip coating for improved safety',
            'UV-resistant materials for durability',
            'Quick mounting clamps pre-installed'
          ],
          images: [
            { src: '/images/composite-deck.jpg', alt: 'Composite deck panel close-up' },
          ]
        }
      ]}
      
      engineeringInsights={[
        'Documentation and Collaboration',
        'On-site labour costs lowered due to efficient pre-fabrication and minimal heavy lifting.',
        'Lessened environmental disturbance thanks to smaller equipment and fewer site visits.',
      ]}
      
      finalReview="Final Thoughts: yet to finish"
      
      author={{
        name: 'Yun Chu',
        date: '17 December 2024',
        // bio: 'A civil engineer focused on sustainable infrastructure solutions, Alex has overseen numerous modular construction projects that set new benchmarks in efficiency and environmental stewardship.'
      }}
    />
  );
};

export default MyEngineeringProject;
