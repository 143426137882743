import React from 'react';
import Header from '../Header/Header';
import PhotoGallery from '../../components/PhotoGallery/PhotoGallery';
import './EngineeringProjectPage.css';

const EngineeringProjectPage = ({
  title,
  subtitle,
  featuredImage,
  projectIntro,
  galleryImages = [],
  projectPlan = [],
  challenges,
  solutions,
  materials = [],
  engineeringInsights = [],
  finalReview,
  author
}) => {
  return (
    <div>
      <Header 
        title={title}
        subtitle={subtitle}
      />
      <main className="project-container">
        <article>
          {/* Featured Image Section */}
          <div className="featured-image-container">
            <img 
              src={featuredImage.src}
              alt={featuredImage.alt}
              className="featured-image" 
            />
            <div className="image-caption">
              {featuredImage.caption}
            </div>
          </div>

          {/* Project Introduction Section */}
          <section className="project-section">
            <h2>Project Overview</h2>
            <p>{projectIntro}</p>
          </section>

          {/* Project Gallery Section */}
          {galleryImages.length > 0 && (
            <PhotoGallery images={galleryImages} />
          )}

          {/* Project Plan Section */}
          {projectPlan.length > 0 && (
            <section className="project-section">
              <h2>Implementation Plan</h2>
              {projectPlan.map((phase, index) => (
                <div key={index} className="project-phase">
                  <h3>Phase {phase.phaseNumber}: {phase.title}</h3>
                  <ol>
                    {phase.activities.map((activity, i) => (
                      <li key={i}>
                        <i className={`fas fa-${activity.icon}`}></i>
                        {activity.text}
                      </li>
                    ))}
                  </ol>
                </div>
              ))}
            </section>
          )}

          {/* Challenges and Solutions Section */}
          {(challenges || solutions) && (
            <section className="project-section">
              <h2>Challenges & Solutions</h2>
              {challenges && <p><strong>Challenges:</strong> {challenges}</p>}
              {solutions && <p><strong>Solutions:</strong> {solutions}</p>}
            </section>
          )}

          {/* Materials and Components Section */}
          {materials.length > 0 && (
            <section className="project-section">
              <h2>Key Materials and Components</h2>
              {materials.map((item, index) => (
                <div key={index} className="materials-item">
                  <h3>{item.title}</h3>
                  {item.description && <p>{item.description}</p>}
                  {item.bulletPoints && item.bulletPoints.length > 0 && (
                    <ul className="materials-bullets">
                      {item.bulletPoints.map((point, i) => (
                        <li key={i}>{point}</li>
                      ))}
                    </ul>
                  )}
                  {item.images && item.images.length > 0 && (
                    <div className="photo-gallery">
                      {item.images.map((img, i) => (
                        <img
                          key={i}
                          src={img.src}
                          alt={img.alt || `Material image ${i + 1}`}
                          className="gallery-image"
                        />
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </section>
          )}

          {/* Engineering Insights Section */}
          {engineeringInsights.length > 0 && (
            <div className="engineering-insights">
              <h2>Engineering Insights</h2>
              {engineeringInsights.map((insight, index) => (
                <div key={index} className="insight-item">{insight}</div>
              ))}
            </div>
          )}

          {/* Final Review Section */}
          <section className="project-section">
            <h2>Final Review</h2>
            <p>{finalReview}</p>
          </section>

          {/* Author Section */}
          <div className="author-section">
            <div className="author-info">
              <h4>Prepared by {author.name}</h4>
              <h5>Last update: {author.date}</h5>
              <p>{author.bio}</p>
            </div>
          </div>
        </article>
      </main>
    </div>
  );
};

export default EngineeringProjectPage;
